import supabase from '../config/config'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import UserNavbar from '../components/UserNavbar'
import 'aos/dist/aos.css'
import { formatDate } from '../utils/date'

const Dashboard = () => {
  var userid = localStorage.getItem('userid')
  const [quizresults, setQuizResults] = useState([])
  const [games, setGame] = useState('Waiting..')
  const navigate = useNavigate()

  useEffect(() => {
    const userSession = async () => {
      const { data, error } = await supabase.auth.getSession()
      try {
        if (data) {
          const { data: profiles, error } = await supabase
            .from('profiles')
            .select()
            .eq('id', userid)
          if (profiles[0].payement === 'not paid') {
            console.log(profiles[0].payement)
            navigate('/pay')
          } else {
            navigate('/dashboard')
          }
        }
        if (error) {
          console.log(error)
        }
      } catch (e) {
        navigate('/login')
      }
    }

    const fetch = async () => {
      try {
        const { data, error } = await supabase
          .from('Quiz')
          .select('id,partnername,time,created_at')
          .eq('userid', userid)
          .limit(5)
          .order('id', { ascending: false })
        if (data.length === 0) {
          setQuizResults([])
          setGame('No previous games')
        } else if (data) {
          setQuizResults(data)
          setGame('previous games')
        } else if (error) {
          console.log(error)
        }
      } catch (e) {
        console.log('maobane')
      }
    }
    userSession()
    fetch()
  }, [userid])

  return (
    <>
      <UserNavbar />
      <br />
      <section id="about" className="about section-bg">
        <div className="container" data-aos="fade-up" data-aos-delay="300">
          <div className="section-title">
            <h2>{games}</h2>
          </div>
          <div className="container">
            <div className="row">
              {quizresults.map(({ id, partnername, time, created_at }) => (
                <div className="col-lg-4" key={id}>
                  <div className="card card-margin">
                    {/*<div className="card-header no-border">
                      <h5 className="card-title"></h5>
              </div>*/}
                    <div className="card-body pt-0">
                      <div className="widget-49">
                        <div className="widget-49-title-wrapper">
                          <div className="widget-49-date-primary">
                            <span className="widget-49-date-day">{time}</span>
                          </div>
                          <div className="widget-49-meeting-info">
                            <span className="widget-49-pro-title">
                              Vs {partnername}
                            </span>
                            <span className="widget-49-meeting-time">
                              {formatDate(created_at)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Dashboard
