import React from 'react'
import * as Icon from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import supabase from '../config/config'
import { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AdminNavbar from '../components/AdminNavbar'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-dt/js/dataTables.dataTables.js'
import $ from 'jquery'
const Users = () => {
  const [modalUdpateIsOpen, setUpdateIsOpen] = useState(false)
  const [questions, setQuestions] = useState([])
  const [quizzes, setQuizzes] = useState([])
  const [question, setQuestion] = useState('')
  const [get, setGet] = useState('')
  const [updated, setUpdated] = useState(null)
  const navigate = useNavigate()

  const fetch = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select()
        .eq('Subscription', 'false')

      if (data) {
        setQuestions(data)
        $(document).ready(function () {
          setTimeout(function () {
            $('#users').DataTable()
          }, 1000)
        })
      } else if (error) {
        console.log(error)
      }
    } catch (e) {
      console.log('maobane')
    }
  }

  const update = async (getit, pay, email) => {
    if (pay === 'not paid') {
      const { data, error } = await supabase
        .from('profiles')
        .update({ payement: 'paid' })
        .eq('id', getit)
      setUpdated(email)
      fetch()
      if (data) {
        console.log(data)
        setGet('nothing')
      }
      if (error) {
        console.log(error)
      }
    }
    if (pay === 'paid') {
      const { data, error } = await supabase
        .from('profiles')
        .update({ payement: 'not paid' })
        .eq('id', getit)
      setUpdated(email)
      fetch()
      if (data) {
        console.log(data)
      }
      if (error) {
        console.log(error)
      }
    }
  }

  const fetchUserGames = async (getid) => {
    const { data, error } = await supabase
      .from('Quiz')
      .select()
      .eq('userid', getid)

    if (data) {
      console.log(data)
      if (data.length === 0) {
        console.log('nothing')
      } else {
        setQuizzes(data)
      }
    }
    if (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const userSession = async () => {
      const { data, error } = await supabase.auth.getSession()
      try {
        if (data) {
          console.log(data.session.user.user_metadata.level)
          //userid = data.session.user.id
        }
        if (!data.session.user.user_metadata.level) {
          navigate('/dashboard')
        }
        if (error) {
          console.log(error)
        }
      } catch (e) {
        navigate('/login')
      }
    }
    userSession()
    fetch()
  }, [question])

  return (
    <>
      <AdminNavbar />
      <br />
      <section id="about" className="about section-bg">
        <div className="container-lg">
          <div className="table-responsive">
            <div
              className="table-wrapper"
              style={{ backgroundColor: '#fefefe', borderRadius: 5 }}
            >
              {updated && (
                <div className="alert alert-success alert-dismissible fade show">
                  <strong>{updated} </strong>Subscription changed
                </div>
              )}
              <div className="table-title">
                <div className="row">
                  <div className="section-admin">
                    <h2>Users</h2>
                  </div>
                </div>
              </div>
              <table className="table table-striped" id="users">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Subscription</th>
                    <th>View</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map(({ id, email, Subscription, payement }) => (
                    <tr key={id}>
                      <td>{email}</td>
                      <td>{payement === 'paid' ? 'Paid' : 'Not paid'}</td>

                      <td>
                        <button
                          type="submit"
                          className="form-control btn btn-primary rounded submit px-3"
                          onClick={() => {
                            setQuizzes([])
                            fetchUserGames(id)
                            setQuestion(id)
                            setUpdateIsOpen(true)
                          }}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          type="submit"
                          className={
                            payement === 'paid'
                              ? 'form-control btn btn-warning rounded submit px-3'
                              : 'form-control btn btn-primary rounded submit px-3'
                          }
                          onClick={() => {
                            update(id, payement, email)
                          }}
                        >
                          {payement === 'paid' ? 'Deactivate' : 'Activate'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalUdpateIsOpen}
        contentLabel="update Question"
        ariaHideApp={false}
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <section id="about" className="about section-bg">
          <div className="container-lg">
            <div className="table-responsive">
              <div
                className="table-wrapper"
                style={{ backgroundColor: '#fefefe', borderRadius: 5 }}
              >
                <div className="table-title">
                  <div className="back">
                    <button onClick={() => setUpdateIsOpen(false)}>
                      <Icon.Arrow90degLeft style={{ width: 20 }} />
                      Back
                    </button>
                  </div>
                  <div className="row">
                    <div className="section-title">
                      <h2>Games played</h2>
                    </div>
                  </div>
                </div>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>First name</th>
                      <th>Surname</th>
                      <th>Partner</th>
                      <th>Profession</th>
                      <th>Partner Profession</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizzes.map(
                      ({
                        id,
                        name,
                        surname,
                        partnername,
                        profession,
                        partnerprofession,
                        time,
                      }) => (
                        <tr key={id}>
                          <td>{name}</td>
                          <td>{surname}</td>
                          <td>{partnername}</td>
                          <td>{profession}</td>
                          <td>{partnerprofession}</td>
                          <td>{time}</td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </>
  )
}

export default Users
