import { Link, useNavigate } from 'react-router-dom'
import supabase from '../config/config'
import { useState, useEffect } from 'react'
import LoadingSpinner from '../components/LoadingSpinner'

const Pay = () => {
  var userid = localStorage.getItem('userid')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const signout = async () => {
    const { error } = await supabase.auth.signOut()
    navigate('/login')
    localStorage.removeItem('userid')
    if (error) {
      console.log('Something went wrong')
    }
  }
  const userSession = async () => {
    const { data, error } = await supabase.auth.getSession()
    try {
      if (data) {
        const { data: profiles, error } = await supabase
          .from('profiles')
          .select()
          .eq('id', userid)
        if (
          profiles[0].payement === 'not paid' &&
          !data.session.user.user_metadata.level
        ) {
          console.log(profiles[0].payement)
          setIsLoading(true)
          navigate('/pay')
        } else if (data.session.user.user_metadata.level === 'admin') {
          setIsLoading(true)
          navigate('/questions-admin')
        } else {
          setIsLoading(true)
          navigate('/dashboard')
        }
      }
      if (error) {
        console.log(error)
      }
    } catch (e) {
      navigate('/login')
    }
  }
  useEffect(() => {
    userSession()
  }, [])
  return (
    <>
      {isLoading ? (
        <>
          <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
              <h1 className="display-1 fw-bold">Subscription</h1>
              <p className="fs-3"> Please pay for the game in order to play.</p>
              {/*<p className="lead">
          You can click on the button below to procced with the payments
        </p>*/}
              <Link className="btn btn-primary" onClick={signout}>
                Back
              </Link>
            </div>
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

export default Pay
