import React from 'react'
import * as Icon from 'react-bootstrap-icons'
import { Link, useNavigate } from 'react-router-dom'
import supabase from '../config/config'
import { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { confirm } from 'react-confirm-box'
import AdminNavbar from '../components/AdminNavbar'
import { formatDate } from '../utils/date';
import { ChromePicker } from 'react-color';
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-dt/js/dataTables.dataTables.js'
import $ from 'jquery'

const Catergories = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalUdpateIsOpen, setUpdateIsOpen] = useState(false)
  const [questions, setQuestions] = useState([])
  const [question, setQuestion] = useState('')
  const [title, setTitle] = useState('')
  const [current, setCurrent] = useState('')
  const [currenttitle, setCurrentTitle] = useState('')
  const [color, setColor] = useState('')
  const [right, setRight] = useState(null)
  const [updated, setUpdated] = useState(null)
  const [successdelete, setSuccessDelete] = useState(null)
  const navigate = useNavigate()

  const optionsWithLabelChange = {
    closeOnOverlayClick: false,
    labels: {
      confirmable: 'Confirm',
      cancellable: 'Cancel',
    },
  }
  const Click = async (options, id) => {
    const result = await confirm('Are you sure?', options)
    if (result) {
      fetchdelete(id)
      return
    }
    console.log('You click No!')
  }

  const fetch = async () => {
    try {
      const { data, error } = await supabase
        .from('Categories')
        .select()
        .order('id', { ascending: false })
      if (data) {
        setQuestions(data)
        $(document).ready(function () {
          setTimeout(function () {
            $('#categories').DataTable()
          }, 1000)
        })
      } else if (error) {
        console.log(error)
      }
    } catch (e) {
      console.log('maobane')
    }
  }

  const fetchdelete = async (tlotlo) => {
    try {
      const deleted = await supabase
        .from('Categories')
        .delete()
        .eq('id', tlotlo)
      console.log(deleted)
      if (deleted) {
        setRight('')
        setUpdated('')
        setSuccessDelete('success')

        fetch()
      }
    } catch (e) {
      console.log('maobane')
    }
  }
  const fill = async (e) => {
    e.preventDefault()
    const inserted = await supabase
      .from('Categories')
      .insert([{ title, color }])
    if (inserted) {
      console.log('working')
      setIsOpen(false)
      fetch()
      setUpdated('')
      setSuccessDelete('')
      setRight('success')
      setTitle('')
    } else {
      alert('Something went wrong')
    }
  }
  const update = async (e) => {
    e.preventDefault()
    const { error } = await supabase
      .from('Categories')
      .update({ title: title, color: color })
      .eq('id', question)

    const updated = await supabase
      .from('Questions')
      .update({ category: title, color: color })
      .eq('category', current)
    if (error) {
      console.log(error)
    }
    if (updated) {
      console.log(updated)
    }
    setUpdateIsOpen(false)
    fetch()
    setRight('')
    setSuccessDelete('')
    setUpdated('success')
    setTitle('')
  }

  useEffect(() => {
    const userSession = async () => {
      const { data, error } = await supabase.auth.getSession()
      try {
        if (data) {
          console.log(data.session.user.user_metadata.level)
          //userid = data.session.user.id
        }
        if (!data.session.user.user_metadata.level) {
          navigate('/dashboard')
        }
        if (error) {
          console.log(error)
        }
      } catch (e) {
        navigate('/login')
      }
    }
    userSession()
    fetch()
  }, [question])
  useEffect(() => {
    setTitle(currenttitle)
  }, [currenttitle])

  return (
    <>
    
      <AdminNavbar />
      <br />
      <section id="about" className="about section-bg">
        <div className="container-lg">
          <div className="table-responsive">
            <div
              className="table-wrapper"
              style={{ backgroundColor: '#fefefe', borderRadius: 5 }}
            >
              {right && (
                <div className="alert alert-success alert-dismissible fade show">
                  <strong>Added!</strong> Category successfully added to the
                  game
                </div>
              )}

              {updated && (
                <div className="alert alert-success alert-dismissible fade show">
                  <strong>Updated!</strong> Category successfully updated
                </div>
              )}

              {successdelete && (
                <div className="alert alert-success alert-dismissible fade show">
                  <strong>Deleted!</strong> Category successfully deleted
                </div>
              )}
              <div className="table-title">
                <div className="row">
                  <div className="col-sm">
                    <div className="section-admin">
                      <h2>Categories</h2>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="section-button">
                      <button onClick={() => setIsOpen(true)}>
                        Add category <Icon.PlusCircle />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-striped" id="categories">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>color</th>
                    <th>Created</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map(({ id, title, color, created_at }) => (
                    <tr key={id}>
                      <td>{title}</td>
                      <td>{color}</td>
                      <td>{formatDate(created_at)}</td>
                      <td>
                        <Link
                          className="edit"
                          title="Edit"
                          data-toggle="tooltip"
                          onClick={() => {
                            setQuestion(id)
                            setCurrentTitle(title)
                            setCurrent(title)
                            setColor(color)
                            setUpdateIsOpen(true)
                          }}
                        >
                          <Icon.Pen />
                        </Link>
                        <Link
                          className="delete"
                          title="Delete"
                          data-toggle="tooltip"
                          onClick={() => {
                            setQuestion(id)
                            Click(optionsWithLabelChange, id)
                          }}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        >
                          <Icon.Trash />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Insert Question"
        ariaHideApp={false}
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <div className="section-title">
          <section className="ftco-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-10">
                  <div className="wrap d-md-flex">
                    <div className="login-wrap p-4 p-md-5">
                      <div className="d-flex"></div>
                      <form className="signin-form" onSubmit={fill}>
                        <div className="form-group mb-3">
                          <label className="label" htmlFor="name">
                            Add Color
                          </label>
                          <br />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Category"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                          <br />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Color"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            required
                          />
                          <br/>
                            <ChromePicker
                            color={color}
                            onChange={(newColor) => setColor(newColor.hex)} st/>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="form-control btn btn-primary rounded submit px-3"
                          >
                            Submit
                          </button>
                          <button
                            style={{ marginTop: 10 }}
                            onClick={() => setIsOpen(false)}
                            className="form-control btn btn-danger rounded submit px-3"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Modal>

      <Modal
        isOpen={modalUdpateIsOpen}
        contentLabel="update Question"
        ariaHideApp={false}
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <div className="section-title">
          <section className="ftco-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-10">
                  <div className="wrap d-md-flex">
                    <div className="login-wrap p-4 p-md-5">
                      <div className="d-flex"></div>
                      <form className="signin-form" onSubmit={update}>
                        <div className="form-group mb-3">
                          <label className="label" htmlFor="name">
                            Update Category
                          </label>
                         
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder={currenttitle}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                          <br/>
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder={currenttitle}
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            required
                          />
                          <br/>
                            <ChromePicker
                            color={color}
                            onChange={(newColor) => setColor(newColor.hex)} st/>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="form-control btn btn-primary rounded submit px-3"
                          >
                            Submit
                          </button>

                          <button
                            style={{ marginTop: 10 }}
                            onClick={() => setUpdateIsOpen(false)}
                            className="form-control btn btn-danger rounded submit px-3"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Modal>
    </>
  )
}

export default Catergories
