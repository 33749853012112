import React from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import supabase from '../config/config'
import { useNavigate, useLocation } from 'react-router-dom'

const AdminNavbar = () => {
  const navigate = useNavigate()
  const signout = async () => {
    const { error } = await supabase.auth.signOut()
    navigate('/login')
    localStorage.removeItem('userid')
    if (error) {
      console.log('Something went wrong')
    }
  }
  const location = useLocation()
  return (
    <Navbar bg="light" expand="lg" variant="light" fixed="top">
      <Container>
        <Navbar.Brand
          href="/"
          className="logo"
          style={{ height: 70, fontSize: 30 }}
        >
          KQS<span>.</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={location.pathname} className="ms-auto ">
            <Nav.Link href="/questions-admin">Home</Nav.Link>
            <Nav.Link href="/users">Users</Nav.Link>
            <Nav.Link href="/adminCreation">Add Admin</Nav.Link>
            <Nav.Link href="/professions">Professions</Nav.Link>
            <Nav.Link href="/categories">Categories</Nav.Link>
            <Nav.Link onClick={signout}>Log out</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default AdminNavbar
