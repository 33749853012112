import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import supabase from '../config/config'
import HomeNavbar from '../components/HomeNavbar'
import Footer from '../components/Footer'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Policy = () => {
  const navigate = useNavigate()
  useEffect(() => {
    AOS.init()
    const userSession = async () => {
      const { data, error } = await supabase.auth.getSession()
      try {
        if (data) {
          console.log(data.session.user.id)
          navigate('/dashboard')
        }
        if (error) {
          console.log(error)
        }
      } catch (e) {}
    }
    userSession()
  })

  return (
    <>
      <HomeNavbar />
      <br />
      <section id="about" className="about section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h3>
              Privacy and Cookie Policy<span>- KQS 60’S</span>
            </h3>
            <h2>General</h2>

            <p>
              King Qhawe Solutions (PTY)LTD, located at Northriding, Randburg,
              Johannesburg, 2188 is responsible for collection and processing of
              all personal information collection through KQS 60’s game and
              Qhawe solutions via http://qhawesolutions.co.za. <br />
              King Qhawe Solutions respects the privacy of all collected
              information and complies with relevant applicable laws including
              the POPI Act 2013 (Protection of Personal Information).
            </p>
            <h2>Storage, Processing and Transfer of personal information</h2>

            <p>
              King Qhawe Solutions (PTY)LTD will collect and process the
              information collected through their website/s in a reasonably
              appropriate manner within its technical and organizational
              measures. However, King Qhawe Solutions (PTY)LTD cannot guarantee
              the security of any information collected and processed through
              the website and the user accepts the risk of providing this
              information on the website/s belonging to King Qhawe Solutions
              (PTY)LTD. King Qhawe Solutions (PTY)LTD may transfer your personal
              information to its partners and third party entities in South
              Africa and outside South Africa who may not necessarily provide
              the same level of protection King Qhawe Solutions (PTY)LTD
              provides.
            </p>

            <h2>Access, rectification and deletion </h2>

            <p>
              You can request King Qhawe Solutions (PTY)LTD to provide you with
              access to the personal information collected about you or your
              entity at any time. You can also request King Qhawe Solutions
              (PTY)LTD to rectify any information collected and processed as
              well as delete or remove any of this information. For any queries
              relating to anything on the King Qhawe Solutions (PTY)LTD page or
              website, you can contact kqscards@gmail.com .
            </p>

            <h2>Cookie Policy</h2>

            <p>
              King Qhawe Solutions (PTY)LTD makes use of “cookies” stored on
              your user device. Cookies used on the webisite are used for the
              following reasons:
            </p>

            <h2>Functionality: </h2>

            <p>
              The cookies used to improve the website for users i.e. a cookie is
              used to remember your settings and remember your information
              without which you would have to re-submit this information. These
              cookies are stored on your device to remain valid for use during
              your session on the website, except for the cookies that remember
              if you are logged in or logged out
            </p>
            <h2>Analytics:</h2>

            <p>
              King Qhawe Solutions (PTY)LTD uses cookies to analyse how users
              visit the website/s so that we can make improvements to the
              efficiency and relevancy of the website/s
            </p>
            <h2>Third party:</h2>

            <p>
              King Qhawe Solutions (PTY)LTD allows its partners and third party
              entities to use cookies to allow users to share content on social
              media networks as well as to enhance the efficiency and user
              friendliness of the website/s
            </p>
            <h2>Changing stored settings</h2>

            <p>
              You can change settings in your web browser to accept, manage or
              reject cookies for the King Qhawe Solutions (PTY)LTD website, it’s
              partners and third party entities.
            </p>
            <h2>Contact</h2>

            <p>
              Feel free to contact King Qhawe Solutions (PTY)LTD on
              http://qhawesolutions.co.za or kqscards@gmail.com or
              kingqhawesolutions7@gmail.com for any queries or any questions
              relating to the website/ss or service provided by the company as
              well as concerns or comments relating to the management of
              cookies. Users of the website are encouraged to regularly read the
              privacy policy and cookie policy for any possible changes or
              amendments.
            </p>
          </div>
        </div>
        <Footer />
      </section>
    </>
  )
}

export default Policy
