import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import supabase from '../config/config'
import HomeNavbar from '../components/HomeNavbar'
import { useParams } from 'react-router-dom'
const NewPassword = () => {
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')
  const [wrong, setWrong] = useState(null)
  const [right, setRight] = useState(null)
  //const { token } = useParams()

  useEffect(() => {
      
    }, [])
  const handlePasswordReset = async (e) => {
    e.preventDefault()
    try {
       if(password!==confirmpassword){
       setWrong('success')
    }
       else {
        const { error } = await supabase.auth.updateUser({
          password:password
        })
        
        if (error) {
          alert('failed')
          console.log(error);
        }else{
          setRight('success')
        }
      }
    } catch (error) {
      console.error(error)
      // Handle error, e.g. show error message to the user
      alert('Failed to reset password. Please try again.')
    }
  }

  return (
    <>
      <HomeNavbar />
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo">
            <a href="/">
              KQS<span>.</span>
            </a>
          </h1>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link className="nav-link scrollto" to="/">
                  Home
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="wrap d-md-flex">
                <div className="login-wrap p-4 p-md-5">
                  <div className="d-flex"></div>
                  <form className="signin-form" onSubmit={handlePasswordReset}>
                    {wrong && (
                      <div className="alert alert-danger alert-dismissible fade show">
                        <strong>Check!</strong> Passwords do not match
                      </div>
                    )}
                    {right && (
                      <div className="alert alert-success alert-dismissible fade show">
                        <strong>Check!</strong> Password has been reset go to login page
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <label className="label" htmlFor="name">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={confirmpassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="form-control btn btn-primary rounded submit px-3"
                      >
                        Reset password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default NewPassword