import { useEffect, useState } from 'react'
import supabase from '../config/config'
import HomeNavbar from '../components/HomeNavbar'
const Create = () => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [province, setProvince] = useState('')
  const [proffesion, setProffesion] = useState('')
  const [password, setPassword] = useState('')
  const [wrong, setWrong] = useState(null)
  const [right, setRight] = useState(null)
  const [professions, setProfessions] = useState([])
  const fetch = async () => {
    try {
      const { data, error } = await supabase.from('Professions').select()
      if (data) {
        console.log(data)
        setProfessions(data)
      } else if (error) {
        console.log(error)
      }
    } catch (e) {
      console.log('maobane')
    }
  }
  const fetchuser = async (e) => {
    e.preventDefault()
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('email')
        .eq('email', email)
      if (data) {
        console.log(data)
        if (data.length === 0) {
          setRight(null)
          setWrong('success')
          handleSubmit()
        } else {
          setWrong(null)
          setRight('success')
        }
      } else if (error) {
        console.log(error)
      }
    } catch (e) {
      console.log('maobane')
    }
  }
  const handleSubmit = async () => {
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        data: {
          first_name: name,
          last_name: surname,
          province: province,
          profession: proffesion,
        },
      },
    })
    if (data) {
      console.log(data)
    } else if (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetch()
  }, [])
  return (
    <>
      <HomeNavbar />
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-6">
              <div className="wrap d-md-flex">
                <div className="login-wrap p-4 p-md-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Sign Up</h3>
                    </div>
                  </div>
                  <form onSubmit={fetchuser} className="signin-form">
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="Last">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="email">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Select Province
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={province}
                        onChange={(e) => setProvince(e.target.value)}
                        required
                      >
                        <option value="">Select province</option>
                        <option value="Free state">Free state</option>
                        <option value="Gauteng">Gauteng</option>
                        <option value="Kwa-zulu natal">Kwa-zulu natal</option>
                        <option value="Mpumalanga">Mpumalanga</option>
                        <option value="Eastern cape">Eastern cape</option>
                        <option value="Limpopo">Limpopo</option>
                        <option value="Northern Cape">Northern Cape</option>
                        <option value="North west">North west</option>
                        <option value="Western Cape">Western Cape</option>
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Profession
                      </label>

                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={proffesion}
                        onChange={(e) => setProffesion(e.target.value)}
                        required
                      >
                        <option value="">Select Profession</option>
                        {professions.map(({ id, title }) => (
                          <>
                            <option key={id} value={title}>
                              {title}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>

                    <div className="form-group mb-3">
                      <label className="label" htmlFor="Password">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <p className="text-center">
                      <a
                        href="/policy"
                        data-toggle="Agree to terms and conditions"
                        style={{
                          textDecoration: 'none',
                          color: '#03cae4',
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        Agree to terms and conditions
                      </a>
                      <input className="primary" type="checkbox" required />
                    </p>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="form-control btn btn-primary rounded submit px-3"
                      >
                        Sign Up
                      </button>
                    </div>
                    {wrong && (
                      <div className="alert alert-success alert-dismissible fade show">
                        <strong>success!</strong> Registration successful please
                        check your email for confirmation
                      </div>
                    )}
                    {right && (
                      <div className="alert alert-warning alert-dismissible fade show">
                        <strong>Account exists!</strong> Email aleardy exists
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Create
