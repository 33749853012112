import React from 'react'
import * as Icon from 'react-bootstrap-icons'
import { Link, useNavigate } from 'react-router-dom'
import supabase from '../config/config'
import { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { confirm } from 'react-confirm-box'
import AdminNavbar from '../components/AdminNavbar'
import { formatDate } from '../utils/date'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-dt/js/dataTables.dataTables.js'
import $ from 'jquery'

const Questions = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalUdpateIsOpen, setUpdateIsOpen] = useState(false)
  const [questions, setQuestions] = useState([])
  const [question, setQuestion] = useState('')
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])
  const [currenttitle, setCurrentTitle] = useState('')
  const [title, setTitle] = useState(null)
  const [right, setRight] = useState(null)
  const [color, setColor] = useState('')
  const [updated, setUpdated] = useState(null)
  const [successdelete, setSuccessDelete] = useState(null)
  const navigate = useNavigate()

  const optionsWithLabelChange = {
    closeOnOverlayClick: false,
    labels: {
      confirmable: 'Confirm',
      cancellable: 'Cancel',
    },
  }
  const Click = async (options, id) => {
    const result = await confirm('Are you sure?', options)
    if (result) {
      fetchdelete(id)
      return
    }
    console.log('You click No!')
  }
  const fetch = async () => {
    try {
      const { data, error } = await supabase
        .from('Questions')
        .select()
        .order('id', { ascending: false })
      if (data) {
        setQuestions(data)
        $(document).ready(function () {
          setTimeout(function () {
            $('#questions').DataTable()
          }, 1000)
        })
      } else if (error) {
        console.log(error)
      }
    } catch (e) {
      console.log('maobane')
    }
  }
  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase.from('Categories').select()
      if (data) {
        setCategories(data)
      } else if (error) {
        console.log(error)
      }
    } catch (e) {
      console.log('maobane')
    }
  }

  const fetchdelete = async (tlotlo) => {
    try {
      const deleted = await supabase.from('Questions').delete().eq('id', tlotlo)
      console.log(deleted)
      if (deleted) {
        setRight('')
        setUpdated('')
        setSuccessDelete('success')

        fetch()
      }
    } catch (e) {
      console.log('maobane')
    }
  }
  const fill = async (e) => {
    e.preventDefault()
    const inserted = await supabase
      .from('Questions')
      .insert([{ title, category, color }])
    if (inserted) {
      setIsOpen(false)
      fetch()
      setUpdated('')
      setSuccessDelete('')
      setRight('success')
      setTitle('')
      setCategory('')
    } else {
      alert('Something went wrong')
    }
  }
  const update = async (e) => {
    e.preventDefault()
    const { error } = await supabase
      .from('Questions')
      .update({ title: title, color: color,category:category })
      .eq('id', question)

    if (error) {
      console.log(error)
    }
    setUpdateIsOpen(false)
    fetch()
    setRight('')
    setSuccessDelete('')
    setUpdated('success')
    setTitle('')
  }

  useEffect(() => {
    const userSession = async () => {
      const { data, error } = await supabase.auth.getSession()
      try {
        if (data) {
          console.log(data.session.user.user_metadata.level)
          //userid = data.session.user.id
        }
        if (!data.session.user.user_metadata.level) {
          navigate('/dashboard')
        }
        if (error) {
          console.log(error)
        }
      } catch (e) {
        navigate('/login')
      }
    }
    userSession()
    fetch()
    fetchCategories()
  }, [question, color])

  useEffect(() => {
    setTitle(currenttitle)
  }, [currenttitle])

  useEffect(() => {
    setColor(color)
    console.log('------------------- color -------------------')
    console.log(color)
    console.log('------------------- color -------------------')
  }, [color])

  return (
    <>
      <AdminNavbar />
      <br />
      <section id="about" className="about section-bg">
        <div className="container-lg">
          <div className="table-responsive">
            <div
              className="table-wrapper"
              style={{ backgroundColor: '#fefefe', borderRadius: 5 }}
            >
              {right && (
                <div className="alert alert-success alert-dismissible fade show">
                  <strong>Added!</strong> Question successfully added to the
                  game
                </div>
              )}

              {updated && (
                <div className="alert alert-success alert-dismissible fade show">
                  <strong>Updated!</strong> Question successfully updated
                </div>
              )}

              {successdelete && (
                <div className="alert alert-success alert-dismissible fade show">
                  <strong>Deleted!</strong> Question successfully deleted
                </div>
              )}
              <div className="table-title">
                <div className="row">
                  <div className="col-sm">
                    <div className="section-admin">
                      <h2>Questions</h2>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="section-button">
                      <button onClick={() => setIsOpen(true)}>
                        Add question <Icon.PlusCircle />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-striped" id="questions">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Color</th>
                    <th>Created</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map(
                    ({ id, title, category, color, created_at }) => (
                      <tr key={id}>
                        <td>{title}</td>
                        <td>{category}</td>
                        <td>{color}</td>
                        <td>{formatDate(created_at)}</td>
                        <td>
                          <Link
                            className="edit"
                            title="Edit"
                            data-toggle="tooltip"
                            onClick={() => {
                              setQuestion(id)
                              setCategory(category)
                              setColor(color)
                              setCurrentTitle(title)
                              setUpdateIsOpen(true)
                            }}
                          >
                            <Icon.Pen />
                          </Link>
                          <Link
                            className="delete"
                            title="Delete"
                            data-toggle="tooltip"
                            onClick={() => {
                              setQuestion(id)
                              Click(optionsWithLabelChange, id)
                            }}
                            style={{ marginLeft: 10, marginRight: 10 }}
                          >
                            <Icon.Trash />
                          </Link>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <br />
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Insert Question"
        ariaHideApp={false}
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <div className="section-title">
          <section className="ftco-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-10">
                  <div className="wrap d-md-flex">
                    <div className="login-wrap p-4 p-md-5">
                      <div className="d-flex"></div>
                      <form className="signin-form" onSubmit={fill}>
                        <div className="form-group mb-3">
                          <label className="label" htmlFor="name">
                            Add question
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Question"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                          <br />{' '}
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={category}
                            onChange={(e) => {
                              console.log('Value: ' + e.target.value)
                              setCategory(e.target.value)

                              categories.forEach((category) => {
                                if (category.title === e.target.value) {
                                  setColor(category.color)
                                }
                              })
                            }}
                            required
                          >
                            <option value="">Select Category</option>
                            {categories.map(({ id, title }) => (
                              <>
                                <option key={id} value={title}>
                                  {title}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="form-control btn btn-primary rounded submit px-3"
                          >
                            Submit
                          </button>
                          <button
                            style={{ marginTop: 10 }}
                            onClick={() => setIsOpen(false)}
                            className="form-control btn btn-danger rounded submit px-3"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Modal>

      <Modal
        isOpen={modalUdpateIsOpen}
        contentLabel="update Question"
        ariaHideApp={false}
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <div className="section-title">
          <section className="ftco-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-10">
                  <div className="wrap d-md-flex">
                    <div className="login-wrap p-4 p-md-5">
                      <div className="d-flex"></div>
                      <form className="signin-form" onSubmit={update}>
                        <div className="form-group mb-3">
                          <label className="label" htmlFor="name">
                            Update question
                          </label>
                          <textarea
                            rows="3"
                            className="form-control"
                            placeholder={currenttitle}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                          <br/>
                                <select
                            className="form-select"
                            aria-label="Default select example"
                            value={category}
                            onChange={(e) => {
                              console.log('Value: ' + e.target.value)
                              setCategory(e.target.value)

                              categories.forEach((category) => {
                                if (category.title === e.target.value) {
                                  setColor(category.color)
                                }
                              })
                            }}
                            required
                          >
                            <option value="">Select Category</option>
                            {categories.map(({ id, title }) => (
                              <>
                                <option key={id} value={title}>
                                  {title}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="form-control btn btn-primary rounded submit px-3"
                          >
                            Submit
                          </button>
                          <button
                            style={{ marginTop: 10 }}
                            onClick={() => setUpdateIsOpen(false)}
                            className="form-control btn btn-danger rounded submit px-3"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Modal>
    </>
  )
}

export default Questions
