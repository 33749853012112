import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import supabase from '../config/config'
import AdminNavbar from '../components/AdminNavbar'
const CreateAdmin = () => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [wrong, setWrong] = useState(null)
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault()
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        data: {
          first_name: name,
          last_name: surname,
          level: 'admin',
        },
      },
    })
    if (data) {
      console.log(data)
      setWrong('success')
    } else if (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    const userSession = async () => {
      const { data, error } = await supabase.auth.getSession()
      try {
        if (data) {
          console.log(data.session.user.user_metadata.level)
          //userid = data.session.user.id
        }
        if (!data.session.user.user_metadata.level) {
          navigate('/dashboard')
        }
        if (error) {
          console.log(error)
        }
      } catch (e) {
        navigate('/login')
      }
    }
    userSession()
  })
  return (
    <>
      <AdminNavbar />
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-6">
              <div className="wrap d-md-flex">
                <div className="login-wrap p-4 p-md-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Create Admin Account</h3>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} className="signin-form">
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="Last">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="email">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label className="label" htmlFor="Password">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="form-control btn btn-primary rounded submit px-3"
                      >
                        Sign Up
                      </button>
                    </div>
                    {wrong && (
                      <div className="alert alert-success alert-dismissible fade show">
                        <strong>success!</strong> Registration successful please
                        check your email for confirmation
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CreateAdmin
