import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import supabase from '../config/config'
import Modal from 'react-modal'
import * as Icon from 'react-bootstrap-icons'
import HomeNavbar from '../components/HomeNavbar'
import Footer from '../components/Footer'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Home = () => {
  const navigate = useNavigate()
  const [modalIsOpen, setIsOpen] = useState(false)
  useEffect(() => {
    AOS.init()
    const userSession = async () => {
      const { data, error } = await supabase.auth.getSession()
      try {
        if (data) {
          const { data: profiles, error } = await supabase
            .from('profiles')
            .select()
            .eq('id', data.session.user.id)
          if (profiles[0].payement === 'not paid') {
            navigate('/')
            if (error) {
              console.log(error)
            }
          } else {
            navigate('/dashboard')
          }
        }
        if (error) {
          console.log(error)
        }
      } catch (e) {}
    }
    userSession()
  })

  return (
    <>
      <HomeNavbar />
      <br />
      <section id="hero" className="d-flex align-items-center">
        <div
          className="container text-center"
          data-aos="zoom-out"
          data-aos-delay="300"
        >
          <h1>
            Welcome to <span>KQS</span>
          </h1>
          <h2>Making pharmacology fun for everyone</h2>
          <a href="#payment" className="btn-get-started">
            Buy Game
          </a>
        </div>
      </section>

      <section id="about" className="about section-bg">
        <div className="container" data-aos="fade-up" data-aos-delay="300">
          <div className="section-title">
            <h2>background</h2>
            <h3>
              Find Out More <span>About Us</span>
            </h3>
            <p>
              The concept of KQS 60's was developed by Mr. Sibabini Khatsha, a
              pharmacist-turned-author and former pharmacology instructor who
              invented the pharmacology game during his tenure as a tutor at the
              Pharmacy School at Nelson Mandela University (2008–2012). He
              discovered that pharmacy students usually had difficulty
              comprehending this particular course. In addition to the fact that
              it felt like a foreign language, the depth of information one
              would have to absorb was daunting, There was no avoiding this
              mountain since pharmacology is one of the specialties at pharmacy
              school. The core of a pharmacist involves their expertise and
              knowledge of pharmacology.
            </p>
          </div>
          <div
            className="section-title"
            data-aos="flip-left"
            data-aos-delay="500"
          >
            <h3>
              How to<span> Play</span>
            </h3>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> A minimum of 2
              players is required to play.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> Each player will
              have an opportunity to quiz the other player on alternate cards.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> Player 1 will
              simply select a card of any colour.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> Player 2 will
              attempt to answer the questions in each card within 60-seconds.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> Switch players
              and continue to the next card.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> The game does NOT
              have answers.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> Participants
              should agree on the correct answer or refer to the relevant
              literature.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> The idea is to
              answer each card within 60seconds and move on to the next card.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> The participants
              who answer all the cards in the quickest time win the game.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> The 60 second
              timer records the time it took to answer the question.
            </p>
            <p>
              <Icon.CheckCircle style={{ color: '03cae4' }} /> If participants
              do not know or agree to the correct answer, they can move on to
              the next question, after the 60second timer goes off.
            </p>
          </div>
        </div>
        <div
          className="section-title"
          data-aos="zoom-out"
          data-aos-delay="300"
          id="payment"
        >
          <h2>Account Details</h2>
          <h3>
            King Qhawe Solutions
            <span>
              {'('}PTY{')'} LTD
            </span>
          </h3>
          <p>Branch code - 7654</p>
          <p>Account Type - Current</p>
          <p>SWIFT code - SBZA ZA JJ</p>
          <p>10 12 109 884 0</p>
        </div>
        <Footer />
      </section>
    </>
  )
}

export default Home
