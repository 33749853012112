const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const formatDate = (rawDate) => {
  var date = new Date(rawDate)
  var day = String(date.getDate()).padStart(2, '0') // Get day and pad with leading zero if necessary
  var month = months[date.getMonth()] // Get month
  var year = date.getFullYear() // Get year

  return day + ' ' + month + ' ' + year
}

export { formatDate }
