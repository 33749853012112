import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import supabase from '../config/config'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useElapsedTime } from 'use-elapsed-time'
import Confetti from 'react-confetti'
import useWindowDimensions from '../hooks/useWindowDimensions'
import UserNavbar from '../components/UserNavbar'
import ReactCardFlip from 'react-card-flip'
import LoadingSpinner from '../components/LoadingSpinner'
import AOS from 'aos'
import 'aos/dist/aos.css'
import water from '../img/water.png'

const Quiz = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const { elapsedTime } = useElapsedTime({ isPlaying })
  const [questions, setQuestions] = useState([])
  const [currentquestion, setCurrent] = useState('')
  const [count, setCount] = useState(0)
  const [arraylength, setArraylength] = useState(0)
  const [length, setLength] = useState(1)
  const [show, setshow] = useState(true)
  const [category, setCategory] = useState('')
  const [color, setColor] = useState('')
  const [key, setKey] = useState(0)
  const [name, setName] = useState('')
  const [surname, setsurName] = useState('')
  const [total, setTotal] = useState('')
  const [partnername, setPname] = useState('')
  const [partnerprofession, setProfession] = useState('')
  const [profession, setUserProfession] = useState('')
  const [userid, setUserId] = useState('')
  const { width, height } = useWindowDimensions()
  const [isLoading, setIsLoading] = useState(true)
  const [isflipped, setIsflipped] = useState(false)

  const navigate = useNavigate()
  var time = ''

  const quizresults = async () => {
    const { data, error } = await supabase.from('Quiz').insert([
      {
        name,
        surname,
        partnername,
        partnerprofession,
        time,
        userid,
        profession,
      },
    ])
    if (data) {
      console.log(data)
    }
    if (error) {
      console.log(error)
    }
  }
  const userSession = async () => {
    const { data, error } = await supabase.auth.getSession()
    try {
      if (data) {
        console.log(data.session.user.id)
        userid = data.session.user.id
        const { data: profiles, error } = await supabase
          .from('profiles')
          .select()
          .eq('id', userid)
        if (profiles[0].payement === 'not paid') {
          console.log(profiles[0].payement)
          navigate('/pay')
        } else {
          navigate('/partner')
        }
      }
      if (error) {
        console.log(error)
      }
    } catch (e) {
      navigate('/login')
    }
  }

  useEffect(() => {
    //userSession()
    AOS.init()
    const fetch = async () => {
      const { data, error } = await supabase.from('Questions').select()
      if (data) {
        if (data.length === 0) {
          navigate('/login')
        } else {
          setQuestions(data)
          console.log(data)
        }
        setIsLoading(false)
      } else if (error) {
        console.log(error)
      }
    }

    fetch()
    setIsPlaying(true)
  }, [count])

  useEffect(() => {
    console.log(questions)
    try {
      setCurrent(questions[count].title)
      setCategory(questions[count].category)
      setArraylength(questions.length)
      setPname(sessionStorage.getItem('pname'))
      setName(localStorage.getItem('name'))
      setColor(questions[count].color)
      console.log(category)
    } catch (e) {
      console.log('not right')
    }
  }, [questions, length, category])

  const increment = () => {
    console.log(questions)
    setCount(count + 1)
    setLength(length + 1)
    setKey((prevKey) => prevKey + 1)
    setIsflipped((prevState) => ({ isFlipped: !prevState.isFlipped }))
    setUserId(localStorage.getItem('userid'))
    setName(localStorage.getItem('name'))
    setsurName(localStorage.getItem('surname'))
    setPname(sessionStorage.getItem('pname'))
    setProfession(sessionStorage.getItem('profession'))
    setUserProfession(localStorage.getItem('profession'))

    if (length >= arraylength) {
      setshow(false)
      setIsPlaying((prevIsPlaying) => !prevIsPlaying)
      const minutes = Math.floor(elapsedTime / 60)
      const seconds = Math.round(elapsedTime % 60)
      function padTo2Digits(num) {
        return num.toString().padStart(2, '0')
      }
      const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
      console.log(result)
      time = result
      setTotal(result)
      quizresults()
    }
  }

  const formatRemainingTime = (time) => {
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = time % 60

    return `${minutes}:${seconds}`
  }

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <div className="timer" style={{ color: '#fff' }}>
          Time Up..
        </div>
      )
    }
    return (
      <div className="timer">
        <div className="text" style={{ color: '#fff' }}>
          Remaining time
        </div>
        <div className="value" style={{ color: '#fff' }}>
          {formatRemainingTime(remainingTime)}
        </div>
      </div>
    )
  }
  const playagain = () => {
    navigate('/partner')
  }
  const gotohome = () => {
    navigate('/dashboard')
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      {show ? (
        <>
          <UserNavbar />
          <br />
          <br />
          <section id="about" className="about section-bg">
            <div className="container" data-aos="fade-down-right">
              <div className="card text-center">
                
                <div
                  className="quiz-container"
                  id="quiz"
                  style={{ backgroundColor: color }}
                >
          
                  <div className="quiz-header">
                    <h6 id="question" style={{ color: '#fff' }}>
                      {name} vs {partnername}
                    </h6>
                    <br />
                    <h5 id="question" style={{ color: '#fff' }}>
                      {length} of {arraylength} questions
                    </h5>
                    <h3 id="question" style={{ color: '#fff' }}>
                      {currentquestion}
                    </h3>
                    <div className="timer-wrapper">
                      <CountdownCircleTimer
                        isPlaying
                        key={key}
                        duration={60}
                        colors={['#fff', color, '#A30000', '#A30000']}
                        colorsTime={[7, 5, 3, 0]}
                        onComplete={() => [true, 1000]}
                      >
                        {renderTime}
                      </CountdownCircleTimer>
                    </div>
                  </div>
                  <button
                    id="submit"
                    onClick={increment}
                    style={{ backgroundColor: color }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <Confetti width={width} height={height} />
          <UserNavbar />
          <br />
          <br />
          <section id="about" className="about section-bg">
            <div className="container" data-aos="fade-up">
              <div className="card text-center">
                <div className="quiz-container" id="quiz">
                  <div className="quiz-header">
                    <h2 id="question">Congrats</h2>
                    <p className="card-text">Time-spent: {total}</p>
                  </div>

                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <button
                          id="submit"
                          onClick={gotohome}
                          style={{ borderRadius: 5 }}
                        >
                          Home
                        </button>
                      </div>
                      <div className="col">
                        <button
                          id="submit"
                          onClick={playagain}
                          style={{ borderRadius: 5 }}
                        >
                          Play Again
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default Quiz
