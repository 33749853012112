import { useNavigate, Link } from 'react-router-dom'
import { useState } from 'react'
import supabase from '../config/config'
import HomeNavbar from '../components/HomeNavbar'
import LoadingSpinner from '../components/LoadingSpinner'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [wrong, setWrong] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault()
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    })

    if (data) {
      try {
        const userid = data.session.user.id
        const name = data.user.user_metadata.first_name
        const surname = data.user.user_metadata.last_name
        const profession = data.user.user_metadata.profession
        localStorage.setItem('userid', userid)
        localStorage.setItem('name', name)
        localStorage.setItem('surname', surname)
        localStorage.setItem('profession', profession)
        setIsLoading(false)

        if (data.user.user_metadata.level === 'admin') {
          navigate('/questions-admin')
          console.log('hala')
        } else {
          //navigate('/dashboard')
          const { data: profiles, error } = await supabase
            .from('profiles')
            .select()
            .eq('id', userid)
          if (profiles[0].payement === 'not paid') {
            console.log(profiles[0].payement)
            navigate('/pay')
          } else if (error) {
            console.log(error)
          } else if (profiles[0].payement === 'paid') {
            navigate('/dashboard')
          }
        }

        console.log(data)
      } catch (e) {
        setWrong('error')
      }
    } else if (error) {
      console.log(error)
    }
  }

  return (
    <>
      {isLoading ? (
        <>
          <HomeNavbar />
          <section className="ftco-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-6">
                  <div className="wrap d-md-flex">
                    <div className="login-wrap p-4 p-md-5">
                      <div className="d-flex">
                        <div className="w-100">
                          <h3 className="mb-4">Sign In</h3>
                        </div>
                      </div>
                      {wrong && (
                        <div className="alert alert-danger alert-dismissible fade show">
                          <strong>Error!</strong> Wrong password or email
                        </div>
                      )}
                      <form onSubmit={handleSubmit} className="signin-form">
                        <div className="form-group mb-3">
                          <label className="label" htmlFor="name">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label className="label" htmlFor="password">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="form-control btn btn-primary rounded submit px-3"
                          >
                            Sign In
                          </button>
                        </div>
                        <br />
                        <div className="form-group d-md-flex">
                          <div className="w-50 text-md-right">
                            <Link
                              className="scrollto"
                              to="/forgot-password"
                              style={{ color: '#03cae4' }}
                            >
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

export default Login
