import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import supabase from '../config/config'
import UserNavbar from '../components/UserNavbar'
const Partner = () => {
  var userid = ''
  const [name, setName] = useState('')
  const [profession, setProfession] = useState('')
  const [professions, setProfessions] = useState([])
  const navigate = useNavigate()
  const fetch = async () => {
    try {
      const { data, error } = await supabase.from('Professions').select()
      if (data) {
        setProfessions(data)
      } else if (error) {
        console.log(error)
      }
    } catch (e) {
      console.log('maobane')
    }
  }
  const fill = async (e) => {
    e.preventDefault()
    sessionStorage.setItem('pname', name)
    sessionStorage.setItem('profession', profession)
    navigate('/quiz')
  }
  const userSession = async () => {
    const { data, error } = await supabase.auth.getSession()
    try {
      if (data) {
        console.log(data.session.user.id)
        userid = data.session.user.id
        const { data: profiles, error } = await supabase
          .from('profiles')
          .select()
          .eq('id', userid)
        if (profiles[0].payement === 'not paid') {
          console.log(profiles[0].payement)
          navigate('/pay')
        } else {
          navigate('/partner')
        }
      }
      if (error) {
        console.log(error)
      }
    } catch (e) {
      navigate('/login')
    }
  }
  useEffect(() => {
    userSession()
    fetch()
  }, [])

  return (
    <>
      <UserNavbar />
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-6">
              <div className="wrap d-md-flex">
                <div className="login-wrap p-4 p-md-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Enter details for second player</h3>
                    </div>
                  </div>
                  <form onSubmit={fill} className="signin-form">
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Profession
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        required
                      >
                        <option value="">Select Profession</option>
                        {professions.map(({ id, title }) => (
                          <>
                            <option key={id} value={title}>
                              {title}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="form-control btn btn-primary rounded submit px-3"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Partner
