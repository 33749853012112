import React from 'react'
import * as Icon from 'react-bootstrap-icons'

const Footer = () => {
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Contact</h2>
        </div>
        <div
          className="row"
          id="contact-cards"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="col-lg-6">
            <div className="info-box mb-4">
              <i>
                <Icon.CloudMoonFill />
              </i>
              <h3>Our website</h3>
              <p>
                <a
                  href="https://qhawesolutions.co.za"
                  style={{ color: '#03cae4', textDecoration: 'none' }}
                >
                  https://qhawesolutions.co.za
                </a>
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i>
                <Icon.Envelope />
              </i>
              <h3>Email Us</h3>
              <p>kingqhawesolutions7@gmail.com</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i>
                <Icon.Telephone />
              </i>
              <h3>Call Us</h3>
              <p>078 436 8985</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-4">
        <div className="copyright text-center">
          &copy; Copyright{' '}
          <strong>
            <span>KQS</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </section>
  )
}

export default Footer
