import { Link, redirect } from 'react-router-dom'
import { useState } from 'react'
import supabase from '../config/config'
import HomeNavbar from '../components/HomeNavbar'
const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [wrong, setWrong] = useState(null)
  const [left, setLeft] = useState(null)
  const fill = async (e) => {
    e.preventDefault()
    const { data, error } = await supabase.auth.resetPasswordForEmail(email,{
      redirectTo: 'https://pharmain60.com/resetpassword',
    })

    if (data) {
      console.log(data)
      setLeft('')
      setWrong('success')
    }
    if (error) {
      console.log(error)
      setWrong('')
      setLeft('good')
    }
  }

  return (
    <>
      <HomeNavbar />
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo">
            <a href="/">
              KQS<span>.</span>
            </a>
          </h1>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link className="nav-link scrollto" to="/">
                  Home
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="wrap d-md-flex">
                <div className="login-wrap p-4 p-md-5">
                  <div className="d-flex"></div>
                  <form className="signin-form" onSubmit={fill}>
                    {wrong && (
                      <div className="alert alert-success alert-dismissible fade show">
                        <strong>Check Email</strong> The Password reset token
                        has been sent to your email
                      </div>
                    )}
                    {left && (
                      <div className="alert alert-danger alert-dismissible fade show">
                        <strong>Error</strong> For security purposes, you can only request this once every 60 seconds
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="form-control btn btn-primary rounded submit px-3"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default ForgotPassword
