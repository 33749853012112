import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Create from './pages/Create'
import Notfound from './pages/NotFound'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Partner from './pages/Partner'
import Quiz from './pages/Quiz'
import ForgotPassword from './pages/ForgotPassword'
import Questions from './pages/Questions'
import CookieConsent from 'react-cookie-consent'
import Policy from './pages/Policy'
import Pay from './pages/Pay'
import CreateAdmin from './pages/CreateAdmin'
import Professions from './pages/Professions'
import Users from './pages/Users'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Catergories from './pages/Categories'
import NewPassword from './pages/NewPassword'

function App() {
  AOS.init()
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create" element={<Create />} />
          <Route path="/:id" element={<Notfound />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/questions-admin" element={<Questions />} />
          <Route path="/users" element={<Users />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/pay" element={<Pay />} />
          <Route path="/adminCreation" element={<CreateAdmin />} />
          <Route path="/professions" element={<Professions />} />
          <Route path="/categories" element={<Catergories />} />
          <Route path="/resetpassword/" element={<NewPassword />} />
        </Routes>
      </BrowserRouter>

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="POPI"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#fff', background: '#03cae4', fontSize: '13px' }}
        expires={365}
      >
        We use cookies to personalize and enhance your experience on our site.
        Visit our
        <a
          href="/policy"
          style={{
            textDecoration: 'none',
            color: '#03cae4',
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          Privacy Policy
        </a>
        to learn more or manage your personal preferences in our
        <a
          href="/policy"
          style={{
            textDecoration: 'none',
            color: '#03cae4',
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          Cookie Consent Tool.
        </a>
        By clicking the "Accept" button, you agree to our use of cookies and
        it’s
        <a
          href="/policy"
          style={{
            textDecoration: 'none',
            color: '#03cae4',
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          terms and conditions
        </a>
      </CookieConsent>
    </>
  )
}

export default App
